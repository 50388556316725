"use client";

import Image from "@/components/common/Image";
import Typewriter from "@/components/components/Typewriter";
import Link from "next/link";

export default function NotFound() {
  return (
    <div className="flex flex-col gap-4 justify-center items-center text-lg">
      <Image src="/Sall-e2point001.png" alt="Sall-e" width={400} height={400} />

      <div className="max-w-full w-[600px] relative top-[-1.5rem] text-center flex flex-col gap-4">
        <div className=" bg-[#CF4C67] text-white p-4 rounded-xl text-xl min-h-[55px] font-medium">
          <Typewriter
            text={`Well this is awkward...I am unable to find the page you are looking for`}
            delay={50}
          />
        </div>
      </div>
    </div>
  );
}
